<template>
  <div class="">
    <a-select mode="multiple" readonly placeholder="请选择管家(输入仅筛选,选择才有效)" v-model="value2" :options="optionsData" @change="handleChange"
      :getPopupContainer="node=>node?node.parentNode:document.body" :filterOption="filterOption"></a-select>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        value2: [],
        optionsData: [],
      }
    },
    props: ["value", "mcity"],
    props: {
      value: {
        type: Array,
        default () {
          return [];
        }
      },
      mcity: {
        type: Number,
        default: 0
      }
    },
    watch: {
      mcity(newVal) {
        if (newVal) {
          this.getOptionsData()
        }
      },
      value(newVal) {
        if (newVal) {
          this.value2 = newVal.map(x => x.id)
        }
      },
    },
    methods: {
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      getOptionsData() {
        this.axios.get(`/api/agent/system/partner/listHousekeeperByCity?manageCityId=${this.mcity}`).then(
          res => {
            let tmpArr = []
            res.body.forEach(x => {
              tmpArr.push({
                value: x.id,
                label: `${x.realName}${x.logAccount}`,
                logAccount: x.logAccount,
                id: x.id,
                realName: x.realName,
              })
            })
            this.optionsData = tmpArr
          }).catch(err => {})
      },
      handleChange(index, nodes) {
        let tmpArr = nodes.map(x => x.data.props)
        this.$emit("update:value", tmpArr)
      }
    },
    created() {
      if (this.mcity) {
        this.getOptionsData()
      }
    }
  }
</script>

<style lang="less" scoped>
</style>
